import React, { useEffect } from 'react';

import {
  AccordionActions,
  AccordionDetails,
  RadioGroup,
  Stack,
} from '@mui/material';

import useI18n from '../../../../../../hooks/internationalization/useI18n';

import Date from '../../../../../../tools/timedate/date/Date';
import CustomActivityPeriodFilterValueParameter from '../../../../../../types/business/filter/data/variants/period/value/variants/custom/CustomActivityPeriodFilterValueParameter';
import ActivityPeriodFilterData from '../../../../../../types/business/filter/data/variants/period/ActivityPeriodFilterData';
import ActivityPeriodFilterValue from '../../../../../../types/business/filter/data/variants/period/value/ActivityPeriodFilterValue';
import ActivityPeriodFilterValueKey, {
  ACTIVITY_PERIOD_FILTER_VALUE_CUSTOM_KEY,
} from '../../../../../../types/business/filter/data/variants/period/value/ActivityPeriodFilterValueKey';

import FilterDialogMoleculeAccordionProps from './FilterDialogMoleculeAccordionProps';
import ButtonAtom from '../../../../atoms/buttons/regular/ButtonAtom';
import RadioAtom from '../../../../atoms/radio/RadioAtom';
import DatePickerAtom from '../../../../atoms/date/picker/DatePickerAtom';
import TypographyAtom from '../../../../atoms/typography/TypographyAtom';
import BaseFilterDialogMoleculeAccordion from './BaseFilterDialogMoleculeAccordion';

import { PRESENT_DATE } from '../../../../../../tools/timedate/date/PresentDate';
import {
  ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY,
  ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY,
} from '../../../../../../types/business/filter/data/variants/period/value/variants/predefined/PredefinedActivityPeriodFilterValueKey';
import { ACTIVITY_PERIOD_FILTER } from '../../../../../../types/business/filter/data/prototype/fields/FilterType';
import { PREDEFINED_ACTIVITY_PERIOD_FILTER_INNER_VALUES } from '../../../../../../types/business/filter/data/variants/period/value/variants/predefined/PredefinedActivityPeriodFilterValue';
import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import {
  DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_FROM_MAPPING,
  DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_TITLE_MAPPING,
  DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_TO_MAPPING,
  DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_LAST_FIVE_YEARS_MAPPING,
  DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_LAST_YEAR_MAPPING,
  DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_NONE_MAPPING,
  DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_TITLE_MAPPING,
  DIALOG_FILTER_ACCORDION_RESET_MAPPING,
} from '../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

const PERIOD_FILTER_VALUE_NONE_KEY = 'NONE';

type PeriodFilterValueKey =
  | ActivityPeriodFilterValueKey
  | typeof PERIOD_FILTER_VALUE_NONE_KEY;

type PeriodNoneFilterValue = {
  key: typeof PERIOD_FILTER_VALUE_NONE_KEY;
  value: undefined;
};

const PERIOD_FILTER_VALUE_NONE: PeriodNoneFilterValue = {
  key: PERIOD_FILTER_VALUE_NONE_KEY,
  value: undefined,
};

type PeriodFilterValue = ActivityPeriodFilterValue | PeriodNoneFilterValue;

type ActivityPeriodFilterDialogMoleculeAccordionProps =
  FilterDialogMoleculeAccordionProps<ActivityPeriodFilterData>;

const ActivityPeriodFilterDialogMoleculeAccordion: React.FC<
  ActivityPeriodFilterDialogMoleculeAccordionProps
> = ({
  className,
  isExpanded,
  initialFilterData: _,
  currentFilterData,
  onChange,
  onChangeExpand,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);

  const [filterValue, setFilterValue] = React.useState<PeriodFilterValue>(
    PERIOD_FILTER_VALUE_NONE,
  );

  const filterToRadio = (
    periodFilterValues: ActivityPeriodFilterData[],
  ): PeriodFilterValue => {
    if (periodFilterValues.length === 1) {
      return periodFilterValues[0].data;
    }
    return PERIOD_FILTER_VALUE_NONE;
  };

  useEffect(() => {
    setFilterValue(filterToRadio(currentFilterData));
  }, [currentFilterData]);

  const resetFilter = () => {
    onChange([]);
  };

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValueKey = event.target.value as PeriodFilterValueKey;
    if (newValueKey === PERIOD_FILTER_VALUE_NONE_KEY) {
      resetFilter();
    }

    if (
      newValueKey === ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY ||
      newValueKey === ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY
    ) {
      const newFilterData =
        PREDEFINED_ACTIVITY_PERIOD_FILTER_INNER_VALUES[newValueKey];
      onChange([
        {
          type: ACTIVITY_PERIOD_FILTER,
          data: newFilterData,
        },
      ]);
    }

    if (newValueKey === ACTIVITY_PERIOD_FILTER_VALUE_CUSTOM_KEY) {
      onChange([
        {
          type: ACTIVITY_PERIOD_FILTER,
          data: {
            key: ACTIVITY_PERIOD_FILTER_VALUE_CUSTOM_KEY,
            value: { end: PRESENT_DATE },
          },
        },
      ]);
    }
  };

  const onCustomStartChange = (newStart?: Date) => {
    onChange([
      {
        type: ACTIVITY_PERIOD_FILTER,
        data: {
          key: ACTIVITY_PERIOD_FILTER_VALUE_CUSTOM_KEY,
          value: {
            start: newStart,
            end: (filterValue.value as CustomActivityPeriodFilterValueParameter)
              ?.end,
          },
        },
      },
    ]);
  };

  const onCustomEndChange = (newEnd?: Date) => {
    onChange([
      {
        type: ACTIVITY_PERIOD_FILTER,
        data: {
          key: ACTIVITY_PERIOD_FILTER_VALUE_CUSTOM_KEY,
          value: {
            start: (
              filterValue.value as CustomActivityPeriodFilterValueParameter
            )?.start,
            end: newEnd,
          },
        },
      },
    ]);
  };

  const renderAvailableRadios = () => (
    <>
      <RadioAtom
        value={PERIOD_FILTER_VALUE_NONE_KEY}
        label={t(DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_NONE_MAPPING)}
      />
      <RadioAtom
        value={ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY}
        label={t(DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_LAST_YEAR_MAPPING)}
      />
      <RadioAtom
        value={ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY}
        label={t(
          DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_LAST_FIVE_YEARS_MAPPING,
        )}
      />
      <RadioAtom
        value={ACTIVITY_PERIOD_FILTER_VALUE_CUSTOM_KEY}
        label={t(DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_TITLE_MAPPING)}
      />
    </>
  );

  const renderCustomRadioDatePickers = () => (
    <Stack
      className='custom__date-pickers'
      direction='row'
      alignItems='center'
      spacing={1}
      marginTop={1}>
      <DatePickerAtom
        className='date-pickers__start'
        value={
          (filterValue.value as CustomActivityPeriodFilterValueParameter)?.start
        }
        maxValue={
          (filterValue.value as CustomActivityPeriodFilterValueParameter)?.end
        }
        disableFuture
        label={t(DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_FROM_MAPPING)}
        onChange={onCustomStartChange}
      />
      <TypographyAtom className='date-pickers__separator' color='text.disabled'>
        –
      </TypographyAtom>
      <DatePickerAtom
        className='date-pickers__end'
        value={
          (filterValue.value as CustomActivityPeriodFilterValueParameter)?.end
        }
        minValue={
          (filterValue.value as CustomActivityPeriodFilterValueParameter)?.start
        }
        disableFuture
        label={t(DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_TO_MAPPING)}
        onChange={onCustomEndChange}
      />
    </Stack>
  );

  return (
    <BaseFilterDialogMoleculeAccordion
      className={`${className} activity-period-accordion`}
      id='activity-period'
      isExpanded={isExpanded}
      title={t(DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_TITLE_MAPPING)}
      data={currentFilterData}
      onChangeExpand={onChangeExpand}>
      <AccordionDetails className='activity-period-accordion__details'>
        <RadioGroup
          className='details__group'
          value={filterValue.key}
          onChange={onRadioChange}>
          {renderAvailableRadios()}
        </RadioGroup>
        {renderCustomRadioDatePickers()}
      </AccordionDetails>
      <AccordionActions className='activity-period-accordion__actions'>
        <ButtonAtom
          className='actions__reset'
          type='reset'
          onClick={resetFilter}
          color='error'
          sx={{ borderRadius: 4 }}>
          {t(DIALOG_FILTER_ACCORDION_RESET_MAPPING)}
        </ButtonAtom>
      </AccordionActions>
    </BaseFilterDialogMoleculeAccordion>
  );
};

export default ActivityPeriodFilterDialogMoleculeAccordion;
