import { Locale } from '../Locale';
import { LOCALE_NAMESPACE_NAME } from '../namespaces/LocaleNamespaceName';

const ru: Locale = {
  [LOCALE_NAMESPACE_NAME.APP]: {
    name: 'ICV',
  },
  [LOCALE_NAMESPACE_NAME.ORGANISMS]: {
    cv: {
      activities: {
        name: 'Активности',
        empty: 'Похоже пользователь ещё не поделился своими активностями.',
      },
      skills: {
        name: 'Навыки',
        empty: 'Похоже пользователь ещё не поделился своими навыками.',
      },
      positions: {
        name: 'Должности',
        empty: 'Похоже пользователь ещё не поделился своими должностями.',
      },
    },
    notFound: {
      header: 'Страница которую вы ищете не существует.',
      fzf: '404',
      back: 'На главную',
    },
    footer: {
      copyright: '© 2024 ICV',
      creator: {
        createdBy: 'Создано',
        createdByCompact: 'создано',
        creatorName: 'Михаилом Линкером',
      },
      links: {
        gitlab: 'Gitlab',
      },
    },
  },
  [LOCALE_NAMESPACE_NAME.MOLECULES]: {
    buttons: {
      contact: {
        text: 'Связаться',
      },
      filter: {
        tooltip: 'Настроить фильтры',
      },
      experience: {
        tooltip: 'Показать опыт',
      },
    },
    cards: {
      activity: {
        type: {
          personal: 'Персональная',
          commercial: 'Коммерческая',
          startup: 'Стартап',
          internship: 'Стажировка',
          education: 'Образовательная',
        },
      },
    },
    charts: {
      skills: {
        default: {
          title: 'Прочие навыки',
        },
      },
    },
    chips: {
      filter: {
        placeholder: {
          text: 'Настроить фильтры',
        },
        prefix: {
          activityType: 'По типу активности',
          activityPosition: 'По должности',
          activityPeriod: 'По периоду',
        },
        activityPeriod: {
          unknown: 'Неопределенный',
          lastYear: 'За последний год',
          lastFiveYears: 'За последние пять лет',
          custom: {
            from: 'С',
            to: 'по',
            until: 'По',
          },
        },
      },
    },
    dialogs: {
      contact: {
        title: 'Связаться',
        close: 'Закрыть',
        alerts: {
          regular: {
            text: 'Пользователь активно ищет работу, потому ответ не займет много времени',
          },
          warning: {
            title: 'Внимание',
            text: 'В данный момент пользователь не находится в поиске работы, потому ответ может занять больше времени чем обычно',
          },
        },
      },
      filter: {
        title: 'Настройка фильтров',
        close: 'Закрыть',
        apply: 'Применить',
        resetAll: 'Сбросить все',
        accordion: {
          reset: 'Сбросить',
          activityType: {
            title: 'По типу активности',
          },
          activityPosition: {
            title: 'По должности',
          },
          activityPeriod: {
            title: 'По периоду',
            none: 'Отсутствует',
            lastYear: 'За последний год',
            lastFiveYears: 'За последние пять лет',
            custom: {
              title: 'Пользовательский период',
              from: 'С',
              to: 'По',
            },
          },
        },
      },
    },
    tables: {
      experience: {
        position: 'Должность',
        experience: 'Опыт',
      },
    },
    text: {
      truncatable: {
        readMore: 'Читать далее',
      },
      copyable: {
        tooltip: {
          success: 'Скопировано',
          failure: 'Не удалось скопировать',
        },
      },
    },
    toggles: {
      theme: {
        tooltip: 'Изменить тему',
      },
      view: {
        listChart: {
          tooltip: 'Отображение Списком или Графиком',
        },
      },
    },
  },
  [LOCALE_NAMESPACE_NAME.ASSETS]: {
    media: {
      svg: {
        icvLogo: {
          alt: 'ICV',
        },
        gitlabLogo: {
          alt: 'GitLab',
        },
        githubLogo: {
          alt: 'GitHub',
        },
        linkedinLogo: {
          alt: 'LinkedIn',
        },
        telegramLogo: {
          alt: 'Telegram',
        },
        avatarPlaceholder: {
          alt: 'Аватар пользователя',
        },
        emptyBox: {
          alt: 'Пустая коробка',
        },
      },
    },
  },
  [LOCALE_NAMESPACE_NAME.TIMEDATE]: {
    dates: {
      present: 'Настоящее время',
    },
  },
};

export default ru;
